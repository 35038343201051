@import '../variables/_grid';

.row {
  margin-left: calc(var(--gutter-width) / 2);
  margin-right: calc(var(--gutter-width) / 2);
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}
