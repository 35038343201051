@import '../variables/_colors';

.site-header {
    position: relative;
    height: 100%;
    width: 100%;
    max-width: 100%;
    padding: 2em 2em 3em 2em;
    background: url(assets/images/header-bg.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);

    & .row {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
}

.site-header__ {
    &logo {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 1;
        height: 100%;
        padding: 0;
    }

    &logo-link {
        display: block;
        width: 14.687em;
        height: 5.9375em;

        & svg {
            width: 100%;
            height: 100%;
        }
    }

    &nav {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        padding-top: 1.875em;
    }

    &nav-links {
        display: flex;
        list-style: none;
        margin-bottom: 2em;
    }

    &nav-link-item + &nav-link-item {
        margin-left: 0.625em;
    }

    &nav-link {
        display: block;
        width: 1.5em;
        height: 1.5em;

        & svg {
            width: 100%;
            height: 100%;
        }
    }
}

html.mobile-nav-active {
    & .site-header {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 20.5em;
        z-index: 3;
    }
}

@media screen and (--breakpoint-lg) {
    html.mobile-nav-active {
        & .site-header {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

@media screen and (--breakpoint-xl) {
    .site-header {
        padding: 0;
        height: 10.25em;

        & .row {
            display: block;
            padding: 0 1em;
            max-width: 1500px;
            margin: 0 auto;
        }
    }

    .site-header__ {
        &nav {
            align-items: flex-end;
        }

        &nav-links {
            margin-bottom: 0;
        }
    }

    html.mobile-nav-active {
        & .site-header {
            height: 10.25em;
        }
    }
}

@media screen and (min-width: 1700px) {
    .site-header {
        & .row {
            padding: 0;
            max-width: initial;
            margin: 0;
        }
    }
}