@import '../variables/_colors';
@import '_link-block-large';
@import '_link-block-medium';
@import '_mass-times';

.featured {
    height: auto;

    & .container {
        position: relative;
        max-width: 79.687em;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        padding: 0 0 5.3em 0;
    }
}

.mobile-wrapper {
    padding: 4em 2em;
}

@media screen and (--breakpoint-md) {
    .featured {
        box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
    }

    .mobile-wrapper {
        display: flex;
        padding: 6em 2em 2em 2em;
    }
}

@media screen and (min-width: 1050px) {
    .mobile-wrapper {
        display: block;
        padding: 0;
    }
}

@media screen and (min-width: 1300px) {
    .featured {
        height: 37.187em;
    }
}