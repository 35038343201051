@import '../variables/_index';

:root {
  --pulse-btn: {
    position: relative;
    overflow: hidden;
  };
  --pulse-btn-after: {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      transform-origin: center center;
      height: 250%;
      width: 100%;
      border-radius: 50%;
      opacity: 0;
  };
  --pulse-btn-hover: {
    animation: pulse 0.8s 1;
  }
}

.pulse-btn-lt {
  @apply --pulse-btn;

  &::after {
      @apply --pulse-btn-after;
      background: rgba(255, 255, 255, 0.15);
  }

  &:hover {
    &::after {
        @apply --pulse-btn-hover;
    }
  }
}

.pulse-btn-dk {
  @apply --pulse-btn;

  &::after {
      @apply --pulse-btn-after;
      background: rgba(0, 0, 0, 0.1);
  }

  &:hover {
    &::after {
        @apply --pulse-btn-hover;
    }
  }
}

.button {
  display: inline-block;
  text-decoration: none;
  width: 10.3rem;
  line-height: 4.25rem;
  transition: all .2s ease;
  font-size: 1rem;

  &:hover {
    text-decoration: none;
  }
}

.button-thin {
  line-height: 2.5rem;
  padding: 0;
}

.button-primary {
  color: var(--colorPrimary);
  border: 2px solid var(--colorPrimary);

  &:hover {
    background: var(--colorPrimary);
    color: var(--colorPrimary);
  }
}

.button-secondary {
  color: var(--colorSecondary);
  border: 2px solid var(--colorSecondary);

  &:hover {
    background: var(--colorSecondary);
    color: var(--colorSecondary);
  }
}
