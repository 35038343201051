@keyframes pulse {
    from {
        transform: translate(-50%, -50%) scale(0);
        opacity: 1;
    }

    to {
        transform: translate(-50%, -50%) scale(2);
        opacity: 0;
    }
}