@import '../variables/_colors';

body.archive {
    & article + article {
        position: relative;
        margin-top: 5em;

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: -2.5em;
            left: 0;
            width: 100%;
            height: 2px;
            border-radius: 2px;
            box-shadow: 0 0 2px 1px rgba(59, 59, 59, 0.1);
            background: var(--colorBody);
        }
    }

    & .entry-title a {
        color: var(--colorPrimary);
    }
}