.home-mass-times {
    list-style: none;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.home-mass-times__ {
    &item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 6.25em;
        text-align: center;

        & p {
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    &item + &item {
        margin-top: 0.9rem;
    }

    &splitter {
        height: 100%;
        width: 2px;
        border: none;
        background: var(--colorPrimary);
        margin: 0 1.25em 0 1.5em;
        transform: rotate(180deg);
    }

    &content,
    &link {
        color: var(--colorPrimary);
    }

    &title-large {
        display: inline-block;
        margin-bottom: -1em;
        font-size: 2.125em;
    }

    &title-small {
      font-size: 1.75em;  
    }

    &content-title {
        font-size: 1.1875em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &content-time {
        font-size: 1.625em;
    }

    &link {
        cursor: pointer;

        &:hover {
            text-decoration: none;
            color: var(--colorSecondary);
        }
    }
}

@media screen and (min-width: 1050px) {
    .home-mass-times {
        flex-direction: row;
        padding-top: 8.125em;
        margin-bottom: 5.312em;
    }

    .home-mass-times__ {
        &item + &item {
            margin-top: 0;
        }
    }
}