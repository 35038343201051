@import '../variables/_colors';

.big-links {
    flex: 1;
    margin-bottom: 8em;
}

.big-links__ {
    &btn {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        max-width: 12em;
        margin: 0 auto;
        width: 100%;
        font-size: 2.125em;
        background: var(--colorPrimary);
        padding: 1.25rem 1.875rem;
        border-radius: 2px;
        color: var(--colorWhite);
        box-shadow: 0 0 8px 1px rgba(59, 59, 59, 0.6);

        &:hover {
            text-decoration: none;
            color: var(--colorWhite);
        }

        & p {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        & svg {
            width: 3.75rem;
            height: 3.75rem;
            margin-bottom: .5rem;
        }
    }

    &btn + &btn {
        margin-top: 2rem;
    }

    &btn-text {
        text-align: center;
    }
}

@media screen and (--breakpoint-md) {
    .big-links {
        margin-bottom: 0;
    }

    .big-links__ {
        &btn {
            margin: 0;
        }
    }
}

@media screen and (min-width: 1050px) {
    .big-links {
        display: flex;
        justify-content: space-around;
        position: absolute;
        top: -5.25em;
        left: 0;
        width: 100%;
        margin-bottom: 5em;
        padding: 0 1em;
    }

    .big-links__ {
        &btn {
            flex: 1;
            max-width: 19.5rem;
        }

        &btn + &btn {
            margin-top: 0;
        }
    }
}

@media screen and (--breakpoint-xl) {
    .big-links {
        justify-content: space-between;
        top: -3.25em;
    }

    .big-links__ {
        &btn {
            flex-direction: row;
            max-width: 22.5rem;

            & svg {
                margin-bottom: 0;
            }
        }
    }
}

@media screen and (min-width: 1300px) {
    .big-links {
        padding: 0;
    }
}