.site-footer {
    position: relative;
    background: url(assets/images/footer-bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 4em 0 2em 0;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);


    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--colorGrayOverlay);
        opacity: 0.95;
    }

    & .container {
        position: relative;
        z-index: 1;
        max-width: 100%;
        padding: 0;
    }

    & .row {
        margin-left: 0;
        margin-right: 0;
        padding: 0 1em;
    }

    & [class*="col-"] {
        padding-left: 0;
        padding-right: 0;
        float: none;
    }

    & .offset-xl-2,
    & .col-xl-2 {
        width: 100%;
    }

    & .offset-xl-2 {
        margin-left: 0;
    }

    & ul {
        list-style: none;
    }
}

.site-footer__ {
    &links.col-xl-2 {
        width: 100%;
        margin-bottom: 2em;
    }

    &link-logo {
        max-width: 20em;
        width: 100%;
        display: block;
        margin: 0 auto 1.25em auto;
    }

    &links-social {
        display: flex;
        justify-content: center;
    }

    &links-social-item + &links-social-item {
        margin-left: 0.625em;
    }

    &links-social-link {
        display: inline-block;
        width: 1.5625em;
        height: 1.5625em;

        & svg {
            width: 100%;
            height: 100%;
        }
    }

    &contact {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 1em;

        & svg {
            width: 2.5em;
            height: 2.5em;
            margin-bottom: 1.875em;
        }
    }

    &contact + &contact {
        margin-top: 2em;
    }

    &contact-title,
    &contact-link {
        color: var(--colorWhite);
        font-size: 1.1875em;
        font-weight: 600;
        text-align: center;
    }

    &contact-link {
        &:hover {
            text-decoration: none;
            color: var(--colorWhite);
        }
    }
}

.site-info {
    width: 100%;
    max-width: 75rem;
    margin: 2.5rem auto 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    color: var(--colorWhite);
    font-size: 0.8125em;
    padding: 0 1rem;

    & p {
        text-align: center;
        margin: 0;
    }
}

.site-info__diocesan-link {
    color: var(--colorWhite);

    &:hover {
        color: var(--colorPrimaryLt);
    }
}

@media screen and (--breakpoint-md) {
    .site-footer {
        & .offset-xl-2,
        & .col-xl-2 {
            width: calc(33% - 1em);
        }
    }

    .site-footer__ {
        &links.col-xl-2 {
            width: 100%;
        }

        &contact {
            display: inline-flex;
        }

        &contact + &contact {
            margin-top: 0;
        }
    }

    .site-info {
        flex-direction: row;
        justify-content: space-between;
    }
}

@media screen and (min-width: 1000px) {
    .site-footer {
        & .row {
            display: flex;
            justify-content: space-around;
        }

        & .offset-xl-2,
        & .col-xl-2 {
            width: 25%;
        }
    }

    .site-footer__ {
        &links.col-xl-2 {
            width: 25%;
            margin-bottom: 0;
        }

        &link-logo {
            max-width: none;
            margin: 0 0 1.25em 0;
        }

        &contact {
            display: flex;
        }
    }
}

@media screen and (min-width: 1500px) {
    .site-footer {
        & .row {
            display: block;
            padding: 0;
        }

        & [class*="col-"] {
            padding-right: .5rem;
            padding-left: .5rem;
            float: left;
        }

        & .offset-xl-2,
        & .col-xl-2 {
            width: 16.66667%;
        }

        & .offset-xl-2 {
            margin-left: 16.66667%;
        }
    }
}