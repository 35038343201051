html {
  box-sizing: border-box;
  background: var(--colorWhite);
}

html,
body {
  width: 100%;
  min-height: 100%;
}

body {
  opacity: 0.96;
  max-width: 1920px;
  margin: 0 auto;
  background: url(assets/images/crossword_95.png);
  background-repeat: repeat;
  background-position: top left;
  position: relative;
  box-shadow: 0 0 5px 1px rgba(30, 30, 30, 0.1);
}

*, *:before, *:after {
  box-sizing: inherit;
}

.wrap {
  height: 100%;
  width: 100%;
}

.simcal-event-title {
  font-size: 1.2em;
  color: var(--colorPrimaryLt);
  font-weight: 600;
}

.simcal-calendar-head h3 {
  font-size: 2em;
}

video[autoplay]:not([muted]) {
  display: none;
}

img {
  display: block;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 300;
  height: auto;
  line-height: 2;
  position: relative;
  text-align: center;
}

img:before {
  content: "We're sorry, the image below is broken :(";
  display: block;
  margin-bottom: 10px;
}

img:after {
  content: "(url: " attr(src) ")";
  display: block;
  font-size: 12px;
}

hr {
  margin: 1em 0;
}

.screen-reader-response {
  position: absolute;
  left: -9999%;
  visibility: hidden;
}

@media screen and (max-width: 782px) {
  :root {
    margin-top: 0 !important;
  }

  #wpadminbar {
    display: none;
  }
}
