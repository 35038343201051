.medium-links {
    padding: 0 1em;
    margin-top: 4em;
}

.medium-links__ {
    &row + &row {
        margin-top: 2em;
    }

    &btn {
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 1.25rem;
        font-size: 1.5em;
        font-weight: 600;
        width: 100%;
        max-width: 10em;
        color: var(--colorPrimary);
        background: var(--colorNeutral);
        box-shadow: 0 0 5px 1px rgba(30, 30, 30, 0.2);
        margin: 0 auto;

        &:hover {
            text-decoration: none;
        }

        & p {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        & svg {
            width: 2.8125rem;
            height: 2.8125rem;
            margin-bottom: .5rem;
        }
    }

    &btn + &btn {
        margin-top: 1em;
    }
}

@media screen and (min-width: 500px) {
    .medium-links {
        display: flex;
        justify-content: center;
    }

    .medium-links__ {
        &row + &row {
            margin-top: 0;
            margin-left: 2em;
        }

        &btn {
            margin: auto;
        }
    }
}

@media screen and (min-width: 1050px) {
    .medium-links {
        margin-top: 0;
    }
}

@media screen and (min-width: 1100px) {
    .medium-links {
        display: block;
    }

    .medium-links__ {
        &row {
            display: flex;
            justify-content: space-around;
        }

        &row + &row {
            margin-top: 2.187em;
            margin-left: 0;
        }

        &btn + &btn {
            margin-top: 0;
        }
    }
}

@media screen and (min-width: 1300px) {
    .medium-links {
        padding: 0;
    }

    .medium-links__ {
        &btn {
            & svg {
                margin-bottom: 0;
            }
        }
    }
}

@media screen and (min-width: 1340px) {
    .medium-links__ {
        &row {
            justify-content: space-between;
        }

        &btn {
            flex-direction: row;
            max-width: 17.812rem;
        }
    }
}