input {
  position: relative;
  z-index: 1;
}

input[type="text"],
input[type="number"],
select,
textarea {
  font-size: 1em;
}

input:not([type="submit"]),
textarea {
  min-width: 20vw;
  padding: 0.625rem 0 0.625rem 0.625rem;
  border: 2px solid var(--colorNeutral);

  &::placeholder {
    font-style: italic;
    opacity: 0.7;
  }
}

input[type="submit"] {
  border: 1px solid transparent;
  background: var(--colorPrimary);
  color: var(--colorWhite);
  transition: all .2s ease;
  padding: 0.625rem 2.5rem;

  &:hover {
    border-color: var(--colorSecondary);
    background: var(--colorSecondary);
  }
}

.wpcf7-recaptcha {
  margin-bottom: 2em;
  position: relative;
  z-index: 0;
}

.search-form {

  & input {
    width: 100%;
    display: block;
    margin-bottom: 1em;
  }
}

@media screen and (min-width: 450px) {
  .search-form {
    & input {
      width: initial;
      display: initial;
      margin-bottom: initial;
    }
  }
}
