@import '../variables/_colors';

.quote-slider {
    visibility: hidden;
    height: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background: url(assets/images/quote-slider-bg.jpg);
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--colorPrimary);
        opacity: 0.8;
    }
}

.quote-slider__ {
    &list {
        position: relative;
        z-index: 1;
        width: 100%;
        max-height: calc(100% - 3em);
        overflow: hidden;
    }

    &list-item {
        width: 100%;
        padding: 2em;

        &:not(.active) {
            display: none;
        }
    }

    &list-item.active {
        animation: .5s ease-in 0s 1 forwards quoteSliderFadeIn;
    }

    &quote {
        text-align: center;
        padding: 0;
        margin: 0;
        border: none;
        color: var(--colorWhite);
    }

    &quote-content {
        font-size: 1.75em;
        margin-bottom: 1.25rem;
        font-family: 'Cinzel', serif;
        letter-spacing: 0.05rem;
        text-transform: uppercase;
        overflow: hidden;

        &::before {
            display: none;
        }
    }

    &quote-author {
        font-size: 1.31em;
        font-style: initial;
    }

    &nav {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 3em;
        z-index: 2; 
    }

    &nav-button {
        width: 1em;
        height: 1em;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.5);
        padding: 0;
        border: 2px solid var(--colorWhite);
    }

    &nav-button + &nav-button {
        margin-left: .5em;
    }

    &nav-button.active {
        background: var(--colorWhite);
    }
}

@media screen and (--breakpoint-md) {
    .quote-slider {
        visibility: visible;
        height: auto;
        padding: 2em;
    }
}

@keyframes quoteSliderFadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}