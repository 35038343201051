@import '../variables/_colors';

body:not(.home).page,
body.single,
body.archive {
  & .wrap {
    max-width: 79em;
    margin: 0 auto;
    display: flex;
    padding: 1em;
    background: var(--colorWhite);
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
  }

  & .main > article {
    &::after {
      content: '';
      display: table;
      clear: both;
    }

    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      &:first-child {
        margin-top: 0;
      }
    }
    
    & ul,
    & dl,
    & ol {
      padding-left: 2rem;
      margin-bottom: 1em;

      & ul,
      & ol {
        list-style: lower-alpha;
      }

      & li,
      & dt,
      & dd {
        margin-bottom: initial;
      }
    }
  }
}

@media screen and (min-width: 350px) {
  body:not(.home).page,
  body.single,
  body.archive {
    & .wrap {
        padding: 2em;
    }
  }
}

@media screen and (--breakpoint-md) {
  .main {
    min-height: calc(100vh - 506px);
  }
}

@media screen and (--breakpoint-xl) {
  body:not(.home).page,
  body.single,
  body.archive {
    & .wrap {
        padding: 4em;
    }

    & .main {
        width: calc(100% - 350px);
        padding: 0 4em 0 0;
    }
  }
}
