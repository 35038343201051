@import '../variables/_colors';

.mpa-slider {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4em 1em;
}

.mpa-slider__container {
    max-width: 87.5em;
    margin: 0 auto;
}

@media screen and (--breakpoint-md) {
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
}

@media screen and (min-width: 900px) {
    .mpa-slider {
        padding: 0;
        height: 25em;
    }
}